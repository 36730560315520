.subscription-plan-card {
  background-color: white;
  width: 100%;
  padding: 48px;
  box-shadow: 2px 2px 6px 2px rgb(0 0 0 / 20%);

  &__title {
    font-size: 32px;
    text-align: center;
    margin-bottom: 16px;
    text-transform: uppercase;
    font-weight: 400;
  }

  &__price {
    text-align: center;
    border-bottom: 1px solid grey;
    height: 132px;

    .price {
      font-size: 48px;
      display: block;
      font-weight: 700;
    }

    .per-month {
      display: block;
      color: gray;
    }
  }

  &__settings {
    margin-top: 48px;

    div {
      &::before {
        content: "✅ ";
      }
    }
  }

  &__button {
    margin-top: 48px;
  }
}
