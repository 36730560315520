/* SCREEN SIZE */
@mixin mobile {
  @media (max-width: 1023px) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: 1024px) and (max-width: 1919px) {
    @content;
  }
}

@mixin desktop-xl {
  @media (min-width: 1920px) {
    @content;
  }
}
