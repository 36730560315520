.page.subscription {
  .subscription__header {
    h1 {
      text-align: center;
    }

    h3 {
      text-align: center;
    }
  }

  .subscription__content {
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    gap: 32px;
  }
}
