.navigation-bar {
  height: 48px;

  .responsive-container {
    display: flex;
    align-items: center;
    height: 100%;

    .responsive-container__content {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;

      .left {
        display: flex;
        gap: 10px;
        justify-content: flex-start;
        align-items: center;

        nav {
          ul {
            margin-block-start: 0;
            margin-block-end: 0;
            margin-inline-start: 0;
            margin-inline-end: 0;
            padding-inline-start: 0;
            display: flex;
            align-items: center;

            li {
              list-style-type: none;
              display: inline-block;
              margin-right: 16px;

              display: block;
            }
          }
        }
      }

      .center {
        display: flex;
        justify-content: center;
      }

      .right {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 16px;
      }
    }
  }

  .mobile {
    .drawer-content {
      margin: 16px;

      ul > li,
      div {
        margin-bottom: 16px;
      }
    }
  }

  .user-settings-dropdown {
    width: 200px;

    .dropdown-section {
      padding: 16px;
      box-sizing: border-box;
    }

    .dropdown-divider {
      width: 100%;
      height: 1px;
      background-color: rgb(38, 38, 51);
    }
  }
}
