.page.time-calculator {
  width: 100%;

  form {
    display: flex;
    flex-direction: column;

    .day-input {
      // display: flex;
      label {
        width: 120px;
        display: inline-flex;
      }
      input {
      }
    }
  }
}
