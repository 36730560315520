$bgcolor: cyan;
$border-color: rgb(83, 83, 112);

.navigation-bar-item-menu {
  position: relative;
  z-index: 20;

  .menu-bg {
    filter: drop-shadow(1px 0 0 $border-color) drop-shadow(-1px 0 0 $border-color) drop-shadow(0 1px 0 $border-color)
      drop-shadow(0 -1px 0 $border-color) drop-shadow(3px 6px 3px rgba(0, 0, 0, 0.3));

    .menu {
      position: absolute;
      top: 20px;
      right: 0;
      background-color: $bgcolor;
      opacity: 0;
      transition: all 0.2s ease-in-out;
      border-radius: 6px;

      &::before {
        content: "";
        width: 16px;
        height: 10px;
        top: -10px;
        right: 12px;
        position: absolute;
        clip-path: polygon(0% 100%, 50% 0%, 100% 100%);
        background: $bgcolor;
      }

      .menu__content {
        position: relative;
        top: 5px;
        transition: all 0.2s ease-in-out;
      }

      &.visible {
        opacity: 1;
        top: 10px;

        .menu__content {
          top: 0;
        }
      }
    }
  }
}
