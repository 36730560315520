// @import url("https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@500;700&display=swap");
// @import url("https://fonts.googleapis.com/css?family=Open+Sans:400,600,700&display=swap");
// @import url("https://fonts.googleapis.com/css?family=Gochi+Hand&display=swap");
// @import url("https://fonts.googleapis.com/css2?family=Birthstone&display=swap");
@import "./mixins";

/* GLOBAL STYLES */
* {
  -webkit-font-smoothing: antialiased;
}

/* Makes options always black and white */
option {
  appearance: none;
}

/* Removes outline */
button,
input,
select,
.primary-button,
.primary-icon-button,
.action-button {
  outline: none;
}

/* Removes list default styles */
ul {
  list-style: none;
}

.link {
  color: -webkit-link;
  cursor: pointer;
  text-decoration: underline;
}

html body {
  margin: 0;
  // font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  //   'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  //   sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: #808b99;
  // color: #000;
  /* font-family: 'Roboto Mono', monospace, sans-serif; */
  //font-family: "Gochi Hand", cursive;
  // font-family: "Open Sans", sans-serif;
  //font-family: "Birthstone", cursive;

  h1 {
    display: block;
    font-size: 2em;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
    font-weight: bold;
  }
}

/* CUSTOM CHROME SCROLLBAR */
/* Let's get this party started */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  /* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); */
  // -webkit-border-radius: 10px;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  /* -webkit-border-radius: 10px; */
  border-radius: 10px;
  background: rgba(64, 64, 64, 0.8);
  /* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); */
}

::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(64, 64, 64, 0.4);
}
