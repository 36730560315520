@import "../../../styles/mixins";
@import "../../../styles/variables";

.responsive-container {
  @include mobile {
    padding-left: var(--margin-sides-mobile);
    padding-right: var(--margin-sides-mobile);
  }

  @include desktop {
    padding-left: var(--margin-sides-desktop);
    padding-right: var(--margin-sides-desktop);
  }

  @include desktop-xl {
    padding-left: 0;
    padding-right: 0;
    max-width: var(--width-desktop-xl);
    margin: 0 auto;
  }

  .responsive-container__content {
    position: relative;

    @include desktop-xl {
      padding-left: 0;
      padding-right: 0;
      width: var(--width-desktop-xl);
    }
  }
}
